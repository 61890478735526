import React, { useState, useCallback, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Radio, Select } from '@linktivity/link-ui';
import { SpecificDateRadioProps, PaymentType } from './SpecificDateRadio.types';
import styles from './radio.module.css';

const DATE_COUNTS = 28;

const dateOptions = Array.from(Array(DATE_COUNTS).keys()).map(i => ({
  label: `${i + 1}`,
  value: `${i + 1}`
}));

const SpecificDateRadio: React.FC<SpecificDateRadioProps> = ({
  dayEndOfMonth = false,
  day = 1,
  disabled = false,
  onChange
}) => {
  const { t } = useTranslation();
  const [paymentType, setPaymentType] = useState('');

  useEffect(() => {
    if (dayEndOfMonth) {
      setPaymentType(PaymentType.monthEnd);
    } else {
      setPaymentType(PaymentType.specificDate);
    }
  }, [dayEndOfMonth]);

  const selectDate = useCallback(
    (date: string) => {
      onChange?.(false, Number(date));
    },
    [onChange]
  );

  const selectPaymentType = useCallback(
    (paymentType: PaymentType) => {
      if (paymentType === PaymentType.monthEnd) {
        onChange?.(true, day);
      } else {
        onChange?.(false, day);
      }
      setPaymentType(paymentType);
    },
    [onChange, day]
  );

  const disabledSelectDate = useMemo(
    () => paymentType === PaymentType.monthEnd,
    [paymentType]
  );

  return (
    <div className={styles.radio}>
      <Radio.Group
        value={paymentType}
        onChange={selectPaymentType}
        disabled={disabled}
      >
        <Radio value={PaymentType.monthEnd}>
          {t('share.agents.detail.monthEnd')}
        </Radio>
        <Radio value={PaymentType.specificDate}>
          {t('share.agents.detail.dateSpecified')}
        </Radio>
      </Radio.Group>
      <Select
        options={dateOptions}
        value={day.toString()}
        onSelect={selectDate}
        disabled={disabledSelectDate || disabled}
        className={styles.select}
      />
    </div>
  );
};

export default SpecificDateRadio;
