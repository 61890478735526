import { AgentMixer, AdminMixer, CommonMixer } from '@linktivity/sagano';
export const MAX_SERVICE_ORDER_NUMBER = 3;
export const BOOKING_RESERVED = '-';
export const EMPTY = '--';
export const IStations: Record<string, string> = {
  1: '嵯峨',
  2: '嵐山',
  3: '保津峡',
  4: '亀岡'
};
export const ITripType = {
  SINGLE: 'single',
  ROUND: 'round'
} as const;
export type ITripType = (typeof ITripType)[keyof typeof ITripType];
export const IDirection = {
  UP: 'up',
  DOWN: 'down'
} as const;
export const IStationDirection = {
  FROM: 'from',
  TO: 'to'
} as const;
export type IStationDirection =
  (typeof IStationDirection)[keyof typeof IStationDirection];
export const IRole = {
  SAGANO_ADMIN: 'saganoAdmin',
  SAGANO_STAFF: 'saganoStaff',
  AGENT_ADMIN: 'agentAdmin',
  AGENT_STAFF: 'agentStaff',
  GROUP_ADMIN: 'groupAdmin',
  GROUP_STAFF: 'groupStaff'
} as const;

export type ListAgentSettlementsPaymentStatusEnum =
  AdminMixer.ListAgentSettlementsPaymentStatusEnum;
export const ListAgentSettlementsPaymentStatusEnum =
  AdminMixer.ListAgentSettlementsPaymentStatusEnum;
export type IRole = (typeof IRole)[keyof typeof IRole];
export type IDirection = (typeof IDirection)[keyof typeof IDirection];
export const GroupTypeId = AdminMixer.GroupTypeId;
export type GroupTypeId = AdminMixer.GroupTypeId;
export const FareTypeId = AdminMixer.FareTypeId;
export type FareTypeId = AdminMixer.FareTypeId;
export const IHumanTypeId = {
  Adult: FareTypeId.Adult,
  Child: FareTypeId.Child
};
export type IHumanTypeId = (typeof IHumanTypeId)[keyof typeof IHumanTypeId];
export const UnitAnnotationId = CommonMixer.UnitAnnotationId;
export type UnitAnnotationId = CommonMixer.UnitAnnotationId;
export const PaymentStatus =
  AdminMixer.SearchServiceOrdersByBookingParamsPaymentStatusEnum;
export type PaymentStatus =
  AdminMixer.SearchServiceOrdersByBookingParamsPaymentStatusEnum;
export const PaymentType =
  AdminMixer.SearchServiceOrdersByBookingParamsPaymentTypeEnum;
export const JpPerfecture = AdminMixer.Code;
export type JpPerfecture = AdminMixer.Code;
export const BookingStatus = AdminMixer.BookingStatus;
export type BookingStatus = AdminMixer.BookingStatus;
export type BookingSubStatus = AdminMixer.BookingSubStatus;
export const BookingSubStatus = AdminMixer.BookingSubStatus;
export const ArrangementState = AdminMixer.ArrangementState;
export type ArrangementState = AdminMixer.ArrangementState;

export const AgentType = AdminMixer.AgentmanAgentType;
export type AgentType = AdminMixer.AgentmanAgentType;

export const AgentStaffType = AdminMixer.AgentStaffType;

export type AgentPaymentType = AgentMixer.AgentPaymentType;
export const AgentPaymentType = AgentMixer.AgentPaymentType;

export type AllotmentLevel = AdminMixer.AllotmentLevel;
export const AllotmentLevel = AdminMixer.AllotmentLevel;

export type AdminNotificationMessage =
  AdminMixer.SearchServiceOrdersByBookingParamsAdminNotificationMsgEnum;
export const AdminNotificationMessage =
  AdminMixer.SearchServiceOrdersByBookingParamsAdminNotificationMsgEnum;

export type AgentNotificationMessage =
  AdminMixer.SearchServiceOrdersByBookingParamsAgentNotificationMsgEnum;
export const AgentNotificationMessage =
  AdminMixer.SearchServiceOrdersByBookingParamsAgentNotificationMsgEnum;

export const AgentPaymentMapping = {
  [AgentType.Free]: AgentPaymentType.Prepaid,
  [AgentType.Contracted]: AgentPaymentType.Coupon,
  [AgentType.SpecialContracted]: AgentPaymentType.Monthly
};

export type SeatType = AdminMixer.SeatType;
export const SeatType = AdminMixer.SeatType;

export type BookingHistoryType = AdminMixer.BookingMainParamsHistoryRespType;
export const BookingHistoryType = AdminMixer.BookingMainParamsHistoryRespType;

export const Role = CommonMixer.Role;
export type Role = CommonMixer.Role;

export const AdminTag = AdminMixer.AdminTag;

export const ServiceServiceStatus = AdminMixer.ServiceServiceStatus;

export const CountryCodes = [
  'UNI',
  'ABW',
  'AFG',
  'AGO',
  'AIA',
  'ALA',
  'ALB',
  'AND',
  'ARE',
  'ARG',
  'ARM',
  'ASM',
  'ATA',
  'ATF',
  'ATG',
  'AUS',
  'AUT',
  'AZE',
  'BDI',
  'BEL',
  'BEN',
  'BES',
  'BFA',
  'BGD',
  'BGR',
  'BHR',
  'BHS',
  'BIH',
  'BLM',
  'BLR',
  'BLZ',
  'BMU',
  'BOL',
  'BRA',
  'BRB',
  'BRN',
  'BTN',
  'BVT',
  'BWA',
  'CAF',
  'CAN',
  'CCK',
  'CHE',
  'CHL',
  'CHN',
  'CIV',
  'CMR',
  'COD',
  'COG',
  'COK',
  'COL',
  'COM',
  'CPV',
  'CRI',
  'CUB',
  'CUW',
  'CXR',
  'CYM',
  'CYP',
  'CZE',
  'DEU',
  'DJI',
  'DMA',
  'DNK',
  'DOM',
  'DZA',
  'ECU',
  'EGY',
  'ERI',
  'ESH',
  'ESP',
  'EST',
  'ETH',
  'FIN',
  'FJI',
  'FLK',
  'FRA',
  'FRO',
  'FSM',
  'GAB',
  'GBR',
  'GEO',
  'GGY',
  'GHA',
  'GIB',
  'GIN',
  'GLP',
  'GMB',
  'GNB',
  'GNQ',
  'GRC',
  'GRD',
  'GRL',
  'GTM',
  'GUF',
  'GUM',
  'GUY',
  'HKG',
  'HMD',
  'HND',
  'HRV',
  'HTI',
  'HUN',
  'IDN',
  'IMN',
  'IND',
  'IOT',
  'IRL',
  'IRN',
  'IRQ',
  'ISL',
  'ISR',
  'ITA',
  'JAM',
  'JEY',
  'JOR',
  'JPN',
  'KAZ',
  'KEN',
  'KGZ',
  'KHM',
  'KIR',
  'KNA',
  'KOR',
  'KWT',
  'LAO',
  'LBN',
  'LBR',
  'LBY',
  'LCA',
  'LIE',
  'LKA',
  'LSO',
  'LTU',
  'LUX',
  'LVA',
  'MAC',
  'MAF',
  'MAR',
  'MCO',
  'MDA',
  'MDG',
  'MDV',
  'MEX',
  'MHL',
  'MKD',
  'MLI',
  'MLT',
  'MMR',
  'MNE',
  'MNG',
  'MNP',
  'MOZ',
  'MRT',
  'MSR',
  'MTQ',
  'MUS',
  'MWI',
  'MYS',
  'MYT',
  'NAM',
  'NCL',
  'NER',
  'NFK',
  'NGA',
  'NIC',
  'NIU',
  'NLD',
  'NOR',
  'NPL',
  'NRU',
  'NZL',
  'OMN',
  'PAK',
  'PAN',
  'PCN',
  'PER',
  'PHL',
  'PLW',
  'PNG',
  'POL',
  'PRI',
  'PRK',
  'PRT',
  'PRY',
  'PSE',
  'PYF',
  'QAT',
  'REU',
  'ROU',
  'RUS',
  'RWA',
  'SAU',
  'SDN',
  'SEN',
  'SGP',
  'SGS',
  'SHN',
  'SJM',
  'SLB',
  'SLE',
  'SLV',
  'SMR',
  'SOM',
  'SPM',
  'SRB',
  'SSD',
  'STP',
  'SUR',
  'SVK',
  'SVN',
  'SWE',
  'SWZ',
  'SXM',
  'SYC',
  'SYR',
  'TCA',
  'TCD',
  'TGO',
  'THA',
  'TJK',
  'TKL',
  'TKM',
  'TLS',
  'TON',
  'TTO',
  'TUN',
  'TUR',
  'TUV',
  'TWN',
  'TZA',
  'UGA',
  'UKR',
  'UMI',
  'URY',
  'USA',
  'UZB',
  'VAT',
  'VCT',
  'VEN',
  'VGB',
  'VIR',
  'VNM',
  'VUT',
  'WLF',
  'WSM',
  'YEM',
  'ZAF',
  'ZMB',
  'ZWE'
];
