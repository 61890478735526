import { action, computed } from 'mobx';
import { IRole } from '@sagano/share/const';
import { adminService } from '@sagano/admin/services';
import { BaseAuth } from '@sagano/share/stores';

const storage = localStorage;

export class Auth extends BaseAuth {
  @action
  getUserDetail = async () => {
    return adminService.getUser({ uid: '' }).then(res => {
      if (res) {
        try {
          storage.setItem(this.USER_DETAIL, JSON.stringify(res));
        } finally {
          this.setUserDetail(res);
        }
      }
    });
  };
  @computed
  get isBackOffice(): boolean {
    return this.userGroup === 'back_office';
  }

  @computed
  get isBackOfficeAdmin(): boolean {
    return this.isBackOffice && this.isAdmin;
  }

  @computed
  get isBackOfficeStaff(): boolean {
    return this.isBackOffice && !this.isAdmin;
  }

  @computed
  get isStation(): boolean {
    return this.userGroup === 'station';
  }

  @computed
  get canLoginUserGroup() {
    return this.isBackOffice || this.isStation;
  }

  @computed
  get role(): IRole {
    if (this.isBackOfficeAdmin) {
      return IRole.SAGANO_ADMIN;
    } else {
      return IRole.SAGANO_STAFF;
    }
  }
}

export default new Auth();
