import { format } from 'date-fns';

export const dateFormat = 'yyyy-MM-dd';
export const yearMonthFormat = 'yyyy-MM';
export const timeFormat = 'HH:mm:ss';
export const timestampFormat = `${dateFormat} ${timeFormat}`;

export const validDateRange = (fromDate?: string, toDate?: string) => {
  const from = parseDate(fromDate);
  const to = parseDate(toDate);
  return !!fromDate && !!toDate && from <= to;
};

export const parseDate = (date?: string) => {
  if (date) {
    const [year, month, day] = date.split('-');
    return new Date(Number(year), Number(month) - 1, Number(day));
  } else {
    return new Date();
  }
};

export const formatDate = (date: Date) => {
  const year = date.getFullYear();
  const month = date.getMonth() + 1;
  const day = date.getDate();
  return `${year}-${month.toString().padStart(2, '0')}-${day
    .toString()
    .padStart(2, '0')}`;
};

export const parseTime = (time?: string) => {
  if (time) {
    const [hour, minute] = time.split(':');
    return new Date(0, 0, 0, Number(hour), Number(minute));
  } else {
    return new Date();
  }
};
export const validTimeRange = (fromTime?: string, toTime?: string) => {
  const from = parseTime(fromTime);
  const to = parseTime(toTime);
  return from <= to && !!fromTime && !!toTime;
};

export const today = () => {
  return formatDate(new Date());
};

export const currentMonthStart = () => {
  const date = new Date();
  date.setDate(1);
  return formatDate(date);
};

export const currentMonthEnd = () => {
  const date = new Date();
  date.setMonth(date.getMonth() + 1);
  date.setDate(0);
  return formatDate(date);
};

export const formatServiceDate = (date: Date | undefined) => {
  if (date) {
    return format(date, timestampFormat);
  } else {
    return '';
  }
};
