import { makeObservable, observable, action, computed, toJS } from 'mobx';
import {
  IReservation,
  IServiceOrder,
  IServiceItem,
  ParticipantGroupReq,
  UpdateBookingParamsOperationRequest,
  CreateBookingReq,
  IUnitItem,
  WheelChair,
  IBasciInfo
} from '../services/types';
import { BookingDetail } from '.';

const storage = sessionStorage;
const RESERVATION = 'reservation';
export default class Reservation {
  @observable reservation: IReservation = storage.getItem(RESERVATION)
    ? JSON.parse(storage.getItem(RESERVATION) || '')
    : {
        bookingId: '',
        participantGroup: {
          unitQuantityItems: [],
          wheelChairs: []
        },
        serviceOrder: {
          serviceOrders: []
        },
        agentId: '',
        agentName: '',
        agentSubGroupName: '',
        agentSubGroupId: '',
        agentType: undefined,
        applicant: {},
        tourConductor: {},
        group: {},
        note: '',
        revision: '',
        updateMainParams: false // update only
      };
  constructor() {
    makeObservable(this);
  }

  @computed
  get bookingId() {
    return this.reservation.bookingId;
  }

  @computed
  get content() {
    return toJS(this.reservation);
  }
  @action
  clear() {
    this.reservation = {
      bookingId: '',
      participantGroup: {
        unitQuantityItems: [],
        wheelChairs: []
      },
      serviceOrder: {
        serviceOrders: []
      },
      agentId: '',
      agentName: '',
      agentSubGroupName: '',
      agentSubGroupId: '',
      agentType: undefined,
      applicant: {},
      tourConductor: {},
      group: {},
      note: '',
      revision: '',
      updateParticipantGroup: false,
      updateServiceOrder: false
    };
    storage.removeItem(RESERVATION);
  }
  @action
  setServiceOrder = (index: number, order: IServiceOrder) => {
    if (this.serviceOrders.length > 0) {
      this.reservation.serviceOrder.serviceOrders[index] = order;
    } else {
      this.reservation.serviceOrder = {
        serviceOrders: [order]
      };
    }
    storage.setItem(RESERVATION, JSON.stringify(this.reservation));
  };
  @action
  addServiceInfo = (
    index: number,
    serviceInfo: IServiceItem,
    directionId: string
  ) => {
    const targetSevice = this.reservation.serviceOrder.serviceOrders?.[
      index
    ]?.services?.find((service: IServiceItem) => {
      return service?.directionId === directionId;
    });
    if (targetSevice) {
      Object.assign(targetSevice, serviceInfo);
      storage.setItem(RESERVATION, JSON.stringify(this.reservation));
    }
  };

  @action
  getAmountCalcReqBody(isUpdate: boolean) {
    const firstServiceOrder = this.reservation.serviceOrder?.serviceOrders?.[0];
    const serviceDays = firstServiceOrder?.services?.map(
      service => service.serviceDay as string
    );
    const unitQuantityItems =
      this.reservation.participantGroup?.unitQuantityItems?.map(item => {
        return {
          unitId: item.unitId,
          quantity: item.quantity
        };
      });
    return {
      bookingId: isUpdate ? this.reservation.bookingId : undefined,
      serviceDays,
      groupType: this.reservation.group?.groupType,
      participantGroup: {
        unitQuantityItems,
        wheelChairs: this.reservation.participantGroup?.wheelChairs
      }
    };
  }
  @computed
  get updateBookingBody(): UpdateBookingParamsOperationRequest {
    const {
      applicant,
      tourConductor,
      group,
      agentSubGroupId,
      revision,
      updateParticipantGroup,
      updateServiceOrder
    } = this.reservation;
    const bookingId = this.reservation.bookingId as string;
    const updateMainParams = updateParticipantGroup || updateServiceOrder;
    const participantGroup = updateParticipantGroup
      ? this.participantGroupReq
      : undefined;
    const serviceOrder = updateServiceOrder ? this.serviceOrderReq : undefined;
    return {
      bookingId,
      body: {
        agentSubGroupId,
        mainParams: updateMainParams
          ? {
              participantGroup,
              serviceOrder,
              revision
            }
          : undefined,
        subParams: {
          applicant,
          tourConductor,
          group
        }
      }
    };
  }
  @computed
  get bookingConfirmBody(): CreateBookingReq {
    const {
      applicant,
      tourConductor,
      group,
      note,
      agentId,
      agentSubGroupId,
      waitListing
    } = this.reservation;
    return {
      applicant,
      tourConductor,
      group,
      waitListing,
      agentId,
      agentSubGroupId,
      note,
      serviceOrder: this.serviceOrderReq,
      participantGroup: this.participantGroupReq
    };
  }
  @computed
  get serviceOrderReq() {
    return {
      serviceOrders: this.serviceOrders?.map((order, index) => {
        return {
          priority: index + 1,
          services: order.services?.map(service => {
            const { serviceId, fromStationId, toStationId, serviceDay } =
              service;
            return {
              serviceId,
              fromStationId,
              toStationId,
              serviceDay
            };
          })
        };
      })
    };
  }
  @computed
  get participantGroupReq(): ParticipantGroupReq {
    const unitQuantityItems =
      this.reservation.participantGroup?.unitQuantityItems?.map(unitItem => {
        const { unitId, quantity } = unitItem;
        return {
          unitId,
          quantity
        };
      });
    return {
      wheelChairs: this.reservation.participantGroup?.wheelChairs,
      unitQuantityItems
    };
  }
  @action
  convertDetailToStore(bookingDetail: BookingDetail) {
    const {
      bookingId,
      applicant,
      tourConductor,
      group,
      agentName,
      agentSubGroupName,
      agentId,
      agentSubGroupId,
      agentType,
      waitListing
    } = bookingDetail.bookingDetail;
    const serviceOrders = bookingDetail.serviceOrders.map(order => {
      const services = order.services?.map(service => {
        const { serviceName, fromStationName, toStationName, ...rest } =
          service;
        return {
          ...rest,
          serviceLabel: serviceName?.shortName,
          fromStationLabel: fromStationName?.shortName,
          toStationLabel: toStationName?.shortName
        };
      }) as IServiceItem[];
      return {
        services,
        isSelected: order.isSelected
      };
    });
    Object.assign(this.reservation, {
      bookingId,
      tourConductor,
      applicant,
      group,
      agentId,
      agentSubGroupId,
      agentName,
      agentSubGroupName,
      agentType,
      waitListing,
      serviceOrder: {
        serviceOrders
      },
      participantGroup: bookingDetail.participantGroup,
      note: '',
      revision: bookingDetail.revision
    });
    storage.setItem(RESERVATION, JSON.stringify(this.reservation));
  }
  @action
  setUnitQuantityItems = (unitQuantityItems: IUnitItem[]) => {
    this.reservation.participantGroup = {
      ...this.reservation.participantGroup,
      unitQuantityItems
    };
    storage.setItem(RESERVATION, JSON.stringify(this.reservation));
  };
  @action
  delServiceOrder = (index: number) => {
    this.reservation.serviceOrder.serviceOrders?.splice(index, 1);
    storage.setItem(RESERVATION, JSON.stringify(this.reservation));
  };
  @action
  setWheelChairs = (wheelChairs: WheelChair[]) => {
    this.reservation.participantGroup = {
      ...this.reservation.participantGroup,
      wheelChairs
    };
    storage.setItem(RESERVATION, JSON.stringify(this.reservation));
  };
  @action
  setBasicInfo = (info: IBasciInfo) => {
    Object.assign(this.reservation, info);
    storage.setItem(RESERVATION, JSON.stringify(this.reservation));
  };
  @computed
  get serviceOrders() {
    return toJS(this.reservation.serviceOrder.serviceOrders) || [];
  }
  @action
  setUpdateServiceOrder = (updateServiceOrder: boolean) => {
    this.reservation.updateServiceOrder = updateServiceOrder;
    storage.setItem(RESERVATION, JSON.stringify(this.reservation));
  };
  @action
  setUpdateParticipantGroup = (updateParticipantGroup: boolean) => {
    this.reservation.updateParticipantGroup = updateParticipantGroup;
    storage.setItem(RESERVATION, JSON.stringify(this.reservation));
  };
}
