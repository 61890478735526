import { makeObservable, observable, action, toJS, computed } from 'mobx';
import { GetBookingDetailsResp, IUnitByIdMap, Note } from '../services/types';
import { PaymentStatus, AgentPaymentType } from '../services/const';

export default class BookingDetail {
  @observable bookingDetail: GetBookingDetailsResp = {};
  unitMap: IUnitByIdMap = {};

  constructor() {
    makeObservable(this);
  }

  @action
  setBookingDetail(detail: GetBookingDetailsResp) {
    this.bookingDetail = detail;
  }
  @action
  setUnitMap(unitMap: IUnitByIdMap) {
    this.unitMap = unitMap;
  }
  @action
  setNotes(notes: Note[]) {
    this.bookingDetail.notes = notes;
  }

  @computed
  get bookingId() {
    return this.bookingDetail.bookingId as string;
  }

  @computed
  get bookingToken() {
    return this.bookingDetail.bookingToken as string;
  }

  @computed
  get bookingHistories() {
    return toJS(this.bookingDetail.mainParamHistories) || [];
  }

  @computed
  get payment() {
    return toJS(this.bookingDetail.mainParams?.payment) || {};
  }

  @computed
  get paymentStatus() {
    return this.payment.status || PaymentStatus.Inited;
  }

  @computed
  get totalAmount() {
    return toJS(this.payment.totalAmount) || {};
  }

  @computed
  get commission() {
    return toJS(this.payment.commission) || {};
  }
  @computed
  get cancellationRatePercent() {
    return this.payment.cancellationRatePercent || 0;
  }

  @computed
  get paymentPrice() {
    return (this.totalAmount.value || 0) - (this.commission.value || 0);
  }

  @computed
  get agentSettlementId() {
    return this.payment.agentSettlementId;
  }

  @computed
  get agentSubGroupSettlementId() {
    return this.payment.agentSubGroupSettlementId;
  }

  @computed
  get paymentMethod() {
    return this.payment.paymentMethod;
  }

  @computed
  get canCreditCardPayment() {
    return (
      this.paymentMethod === AgentPaymentType.Prepaid &&
      this.paymentStatus === PaymentStatus.PaymentPending
    );
  }
  @computed
  get canReportCouponIssued() {
    return (
      this.paymentMethod === AgentPaymentType.Coupon &&
      this.paymentStatus === PaymentStatus.PaymentPending
    );
  }
  @computed
  get cancellationFee() {
    return toJS(this.payment.totalCancellationAmount) || {};
  }

  @computed
  get isCancellationFeeFixed() {
    return !!this.payment.isCancellationFeeFixed;
  }

  @computed
  get isTotalAmountFixed() {
    return !!this.payment.isTotalAmountFixed;
  }

  @computed
  get totalParticipants() {
    return (
      this.unitQuantityPriceItems?.reduce(
        (total, unit) => total + (unit.quantity || 0),
        0
      ) || 0
    );
  }
  @computed
  get totalWheelChairs() {
    return this.participantGroup.wheelChairs?.length || 0;
  }
  @computed
  get bulkReservationId() {
    return this.bookingDetail.mainParams?.bulkReservationId;
  }
  @computed
  get status() {
    return this.bookingDetail.status;
  }
  @computed
  get subStatus() {
    return this.bookingDetail.subStatus;
  }
  @computed
  get serviceOrders() {
    return (
      toJS(this.bookingDetail.mainParams?.serviceOrder?.serviceOrders) || []
    );
  }
  @computed
  get unitQuantityPriceItems() {
    return this.payment?.unitQuantityPriceItems?.map(priceItem => {
      const { annotation, fareType, segment } =
        this.unitMap[priceItem.unitId as string] || {};
      return {
        ...toJS(priceItem),
        annotation,
        fareType,
        segment
      };
    });
  }
  @computed
  get cancellationUnitQuantityPriceItems() {
    return this.payment?.cancellationUnitQuantityPriceItems?.map(priceItem => {
      const { annotation, fareType, segment } =
        this.unitMap[priceItem.unitId as string] || {};
      return {
        ...toJS(priceItem),
        annotation,
        fareType,
        segment
      };
    });
  }
  @computed
  get participantGroup() {
    return {
      unitQuantityItems:
        this.bookingDetail.mainParams?.participantGroup?.unitQuantityItems?.map(
          item => {
            const { unitId, quantity } = item;
            const { annotation, fareType, segment } =
              this.unitMap[item.unitId as string] || {};

            return {
              unitId,
              quantity,
              annotation,
              fareType,
              segment
            };
          }
        ),
      wheelChairs:
        this.bookingDetail.mainParams?.participantGroup?.wheelChairs?.map(
          wheelChair => {
            return toJS(wheelChair);
          }
        )
    };
  }
  @computed
  get revision() {
    return this.bookingDetail.mainParams?.revision;
  }
  @computed
  get selectedServiceOrder() {
    return toJS(this.serviceOrders.find(order => order.isSelected));
  }
  @computed
  get agentSubGroupId() {
    return this.bookingDetail.agentSubGroupId;
  }
  @computed
  get notes() {
    return toJS(this.bookingDetail.notes) || [];
  }
  @computed
  get canUpdate() {
    return this.bookingDetail.canUpdate;
  }
  @computed
  get canCancel() {
    return this.bookingDetail.canCancel;
  }
  @computed
  get caution() {
    return this.bookingDetail.caution;
  }
  @computed
  get canReleaseSeats() {
    return this.bookingDetail.canReleaseSeats;
  }
}
