import { message } from '@linktivity/link-ui';
import { CommonMixer } from '@linktivity/sagano';

const BASE_PATH = import.meta.env.VITE_API_COMMON_HOST;

const config = new CommonMixer.Configuration({
  basePath: BASE_PATH,
  middleware: [
    {
      post: async context => {
        if (!context.response.ok) {
          // handle error
          const data = await context.response.json();
          message.error(
            data.common.error_description || data.common.error_code,
            10
          );
        }
      }
    }
  ]
});
export default new CommonMixer.CommonMixerApi(config);
