import cls from 'clsx';
import styles from './layoutHeader.module.css';

interface LayoutHeaderProps {
  webTitle: string;
  refreshHandle: (e: React.MouseEvent<HTMLAnchorElement>) => void;
  children?: React.ReactNode;
  isProd?: boolean;
}

const LayoutHeader: React.FC<LayoutHeaderProps> = ({
  webTitle,
  refreshHandle,
  children,
  isProd
}) => {
  return (
    <header className={styles.header}>
      <div className={styles.inner}>
        <a
          href="/"
          className={cls(styles.logo, { [styles.notProd]: !isProd })}
          onClick={refreshHandle}
        >
          {webTitle}
        </a>
        <div className={styles.info}>{children}</div>
      </div>
    </header>
  );
};

export default LayoutHeader;
