import { action, computed, toJS, makeObservable, observable } from 'mobx';
import { SeatType } from '../services/const';
import { ArrangementType } from '../services/types';
import { commonService } from '../services';
import { getTextColor, getBorderColor } from '../utils/color';

const storage = sessionStorage;
const ARRANGMENT_TYPES = 'arrangement_types';

export default class ArrangementTypes {
  @observable arrangementTypes: Array<ArrangementType> = JSON.parse(
    storage.getItem(ARRANGMENT_TYPES) || '[]'
  );

  constructor() {
    makeObservable(this);
  }

  @action
  fetchArrangementTypes = async () => {
    if (!this.loaded) {
      commonService.listArrangementTypes().then(res => {
        if (res?.arrangementTypes) {
          try {
            storage.setItem(
              ARRANGMENT_TYPES,
              JSON.stringify(res.arrangementTypes)
            );
          } finally {
            this.setArrangementTypes(res.arrangementTypes);
          }
        }
      });
    } else {
      return this.arrangementTypes;
    }
  };

  @action
  setArrangementTypes = (arrangementTypes: Array<ArrangementType>) => {
    this.arrangementTypes = arrangementTypes;
  };

  @computed
  get wheelchairArrangementTypeId() {
    return (
      this.arrangementTypes.find(type => type.seatType === SeatType.Wheelchair)
        ?.arrangementTypeId || ''
    );
  }
  @computed
  get carStyle() {
    const standingColorHex = this.types.find(
      type => type.seatType === SeatType.Standing
    )?.colorHex;
    const style: Record<string, string> = {
      '--color-arrangment-standing': `#${standingColorHex}`
    };
    this.types.forEach(type => {
      style[
        `--color-arrangment-${type.arrangementTypeId}`
      ] = `#${type.colorHex}`;
      style[`--color-arrangment-text-${type.arrangementTypeId}`] = getTextColor(
        type.colorHex as string
      );
      style[`--color-arrangment-border-${type.arrangementTypeId}`] =
        getBorderColor(type.colorHex as string);
    });
    return style as React.CSSProperties;
  }
  @computed
  get types() {
    return toJS(this.arrangementTypes);
  }

  @computed
  get loaded() {
    return this.arrangementTypes.length > 0;
  }
}
