import { action, computed, makeObservable, observable } from 'mobx';
import { DirectionWithStations } from '../services/types';
import { IDirection } from '../services/const';
import { commonService } from '../services';

const storage = sessionStorage;
const STATIONS = 'stations';

export default class Stations {
  @observable directions: DirectionWithStations[] = JSON.parse(
    storage.getItem(STATIONS) || '[]'
  );
  constructor() {
    makeObservable(this);
  }

  @action
  fetchStations = async () => {
    if (!this.loaded) {
      return commonService.listStations().then(res => {
        if (res?.directions) {
          try {
            storage.setItem(STATIONS, JSON.stringify(res.directions));
          } finally {
            this.setStations(res.directions);
          }
        }
      });
    } else {
      return this.directions;
    }
  };

  @action
  setStations = (stations: DirectionWithStations[]) => {
    this.directions = stations;
  };
  @action
  getStationsByDirection = (directionId: string) => {
    return (
      this.directions.find(direction => direction.directionId === directionId)
        ?.stations || []
    );
  };
  @computed
  get stationWithDirections() {
    return this.directions;
  }
  @computed
  get upStations() {
    return (
      this.directions.find(
        (station: DirectionWithStations) =>
          station.directionId === IDirection.UP
      )?.stations || []
    ).map(station => {
      return {
        stationId: station.stationId,
        stationLabel: station.nameLabels?.shortName
      };
    });
  }
  @computed
  get downStations() {
    return (
      this.directions.find(
        (station: DirectionWithStations) =>
          station.directionId === IDirection.DOWN
      )?.stations || []
    ).map(station => {
      return {
        stationId: station.stationId,
        stationLabel: station.nameLabels?.shortName
      };
    });
  }
  @computed
  get upFirstStation() {
    const { stationId, stationLabel } = this.upStations[0];
    return {
      stationId,
      stationLabel
    };
  }
  @computed
  get downFirstStation() {
    const { stationId, stationLabel } = this.downStations[0];
    return {
      stationId,
      stationLabel
    };
  }
  @computed
  get upLastStation() {
    const { stationId, stationLabel } =
      this.upStations[this.upStations.length - 1];
    return {
      stationId,
      stationLabel
    };
  }
  @computed
  get downLastStation() {
    const { stationId, stationLabel } =
      this.downStations[this.downStations.length - 1];
    return {
      stationId,
      stationLabel
    };
  }
  @computed
  get idMap() {
    const idMap: Record<string, string> = {};
    this.upStations.forEach(station => {
      if (station.stationId) {
        idMap[station.stationId] = station.stationLabel || '';
      }
    });
    return idMap;
  }
  @computed
  get loaded() {
    return this.directions.length > 0;
  }
}
