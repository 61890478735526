import { action, computed, makeObservable, observable, toJS } from 'mobx';
import { commonService } from '../services';
import { FareTypeId, GroupTypeId, UnitAnnotationId } from '../services/const';
import { IUnitByIdMap, IUnitItem, ListUnitRespUnit } from '../services/types';

export default class Units {
  UNITS = 'units';
  storage = sessionStorage;
  @observable units: ListUnitRespUnit[] = JSON.parse(
    this.storage.getItem(this.UNITS) || '[]'
  );
  constructor() {
    makeObservable(this);
  }

  @action
  fetchUnits = async () => {
    if (!this.loaded) {
      return commonService.listUnits().then(res => {
        if (res?.units) {
          try {
            this.storage.setItem(this.UNITS, JSON.stringify(res.units));
          } finally {
            this.setUnits(res.units);
          }
        }
      });
    } else {
      return this.units;
    }
  };

  @action
  setUnits = (units: ListUnitRespUnit[]) => {
    this.units = units;
  };

  @computed
  get loaded() {
    return this.units.length > 0;
  }
  @computed
  get normalUnits() {
    return this.units.filter(unit => unit.segment === GroupTypeId.Normal);
  }
  @computed
  get schoolUnits() {
    return this.units.filter(unit => unit.segment === GroupTypeId.School);
  }
  @computed
  get unitMap() {
    const unitMap: IUnitByIdMap = {};
    this.units.forEach(unit => {
      if (unit.id) {
        unitMap[unit.id] = toJS(unit);
      }
    });
    return unitMap;
  }
  @action
  getUnit = (
    annotation: UnitAnnotationId,
    fareType: FareTypeId,
    groupType: GroupTypeId
  ) => {
    if (groupType === GroupTypeId.Normal) {
      return this.normalUnits?.find(
        unit => unit.annotation === annotation && unit.fareType === fareType
      );
    } else if (groupType === GroupTypeId.School) {
      return this.schoolUnits?.find(
        unit => unit.annotation === annotation && unit.fareType === fareType
      );
    }
  };
  @action
  addUnitId(unitItems: IUnitItem[], groupType: GroupTypeId): IUnitItem[] {
    return unitItems?.map(unit => {
      const { annotation, fareType } = unit;
      return {
        ...unit,
        unitId: this.getUnit(
          annotation as UnitAnnotationId,
          fareType as FareTypeId,
          groupType
        )?.id
      };
    });
  }
}
