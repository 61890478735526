import { action, computed, makeObservable, observable } from 'mobx';
import { adminService } from '@sagano/admin/services';
import { Train } from '@sagano/admin/services/types';

const storage = sessionStorage;
const TRAINS = 'trains';

export class Trains {
  @observable trains: Array<Train> = JSON.parse(
    storage.getItem(TRAINS) || '[]'
  );

  constructor() {
    makeObservable(this);
  }

  @action
  fetchTrains = async () => {
    if (!this.loaded) {
      adminService.listLogicalTrains().then(res => {
        if (res?.trains) {
          try {
            storage.setItem(TRAINS, JSON.stringify(res.trains));
          } finally {
            this.setTrains(res.trains);
          }
        }
      });
    } else {
      return this.trains;
    }
  };

  @action
  setTrains = (trains: Array<Train>) => {
    this.trains = trains;
  };

  @action
  getTrainByLogicalTrainId = (logicalTrainId: string) => {
    return this.trains.find(train => train.logicalTrainId === logicalTrainId);
  };

  @computed
  get trainsList() {
    return this.trains.map(train => ({
      logicalTrainId: train.logicalTrainId,
      directionName: train.directionName
    }));
  }

  @computed
  get loaded() {
    return this.trains.length > 0;
  }
}

export default new Trains();
