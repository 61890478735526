import { action, computed } from 'mobx';
import { parseE6Price } from '@sagano/admin/utils/misc';
import {
  UnitSummary,
  PriceApplicationPricedUnit
} from '@sagano/admin/services/types';
import {
  FareTypeId,
  SegmentId,
  UnitAnnotationId
} from '@sagano/admin/services/const';
import { Units as BaseUnits } from '@sagano/share/stores';

export class Units extends BaseUnits {
  @action
  getUnitsSummaryBySegmentId = (segment?: SegmentId) => {
    return this.unitsSummary.filter(unit => unit.segment === segment);
  };

  @action
  getUnitsPriceBySegmentId = (segment?: SegmentId) => {
    switch (segment) {
      case SegmentId.Normal:
        return this.normalPrice;
      case SegmentId.School:
        return this.schoolPrice;
      default:
        return [];
    }
  };

  @computed
  get normalUnitsSummary() {
    return this.getUnitsSummaryBySegmentId(SegmentId.Normal);
  }

  @computed
  get normalPrice(): PriceApplicationPricedUnit[] {
    return this.normalUnitsSummary.map(unit => {
      const priceUnit = {
        unit: unit,
        priceE6: '0',
        decrementFeeE6: '220'
      };
      switch (unit.annotation) {
        case UnitAnnotationId.Normal: {
          if (unit.fareType === FareTypeId.Adult) {
            priceUnit.priceE6 = '800';
          } else if (unit.fareType === FareTypeId.Child) {
            priceUnit.priceE6 = '400';
          }
          break;
        }
        case UnitAnnotationId.Handicapped1: {
          if (unit.fareType === FareTypeId.Adult) {
            priceUnit.priceE6 = '440';
          } else if (unit.fareType === FareTypeId.Child) {
            priceUnit.priceE6 = '220';
          }
          break;
        }
        case UnitAnnotationId.Handicapped2: {
          if (unit.fareType === FareTypeId.Adult) {
            priceUnit.priceE6 = '440';
          } else if (unit.fareType === FareTypeId.Child) {
            priceUnit.priceE6 = '220';
          }
          break;
        }
        case UnitAnnotationId.Supporter: {
          if (unit.fareType === FareTypeId.Adult) {
            priceUnit.priceE6 = '440';
          } else if (unit.fareType === FareTypeId.Child) {
            priceUnit.priceE6 = '220';
          }
          break;
        }
      }
      // covert price to E6
      priceUnit.priceE6 = parseE6Price(priceUnit.priceE6);
      priceUnit.decrementFeeE6 = parseE6Price(priceUnit.decrementFeeE6);
      return priceUnit;
    });
  }

  @computed
  get schoolPrice(): PriceApplicationPricedUnit[] {
    return this.schoolUnitsSummary.map(unit => {
      const priceUnit = {
        unit: unit,
        priceE6: '0',
        decrementFeeE6: '0'
      };
      switch (unit.annotation) {
        case UnitAnnotationId.Normal: {
          if (unit.fareType === FareTypeId.Adult) {
            priceUnit.priceE6 = '710';
          } else if (unit.fareType === FareTypeId.Child) {
            priceUnit.priceE6 = '360';
          }
          break;
        }
        case UnitAnnotationId.Handicapped1: {
          if (unit.fareType === FareTypeId.Adult) {
            priceUnit.priceE6 = '440';
          } else if (unit.fareType === FareTypeId.Child) {
            priceUnit.priceE6 = '220';
          }
          break;
        }
        case UnitAnnotationId.Handicapped2: {
          if (unit.fareType === FareTypeId.Adult) {
            priceUnit.priceE6 = '440';
          } else if (unit.fareType === FareTypeId.Child) {
            priceUnit.priceE6 = '220';
          }
          break;
        }
        case UnitAnnotationId.Supporter: {
          if (unit.fareType === FareTypeId.Adult) {
            priceUnit.priceE6 = '440';
          } else if (unit.fareType === FareTypeId.Child) {
            priceUnit.priceE6 = '220';
          }
          break;
        }
      }
      // covert price to E6
      priceUnit.priceE6 = parseE6Price(priceUnit.priceE6);
      priceUnit.decrementFeeE6 = parseE6Price(priceUnit.decrementFeeE6);
      return priceUnit;
    });
  }

  @computed
  get schoolUnitsSummary() {
    return this.getUnitsSummaryBySegmentId(SegmentId.School);
  }

  @computed
  get unitsSummary() {
    const unitsSummary: Array<UnitSummary> = [];
    this.units.forEach(unit => {
      // remove free units
      if (unit.annotation !== UnitAnnotationId.Free) {
        unitsSummary.push({
          id: unit.id,
          annotation: unit.annotation,
          fareType: unit.fareType,
          segment: unit.segment
        });
      }
    });

    return unitsSummary;
  }
}

export default new Units();
