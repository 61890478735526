import { action, computed, makeObservable, observable, toJS } from 'mobx';
import { inRange } from '@sagano/admin/utils/misc';
import { adminService } from '@sagano/admin/services';
import { ServiceTerm } from '@sagano/admin/services/types';

const storage = sessionStorage;
const SERVICE_TERMS = 'serviceTerms';

export class ServiceTerms {
  @observable serviceTerms: Array<ServiceTerm> = JSON.parse(
    storage.getItem(SERVICE_TERMS) || '[]'
  );

  constructor() {
    makeObservable(this);
  }

  @action
  fetchServiceTerms = async () => {
    return adminService.listServiceTerms().then(res => {
      if (res.serviceTerms) {
        const serviceTerms = res?.serviceTerms;
        if (serviceTerms) {
          try {
            storage.setItem(SERVICE_TERMS, JSON.stringify(serviceTerms));
          } finally {
            this.setServiceTerms(serviceTerms);
          }
          return serviceTerms;
        }
      }
    });
  };

  @action
  addServiceTerm = async (fromDate: string, toDate: string) => {
    return adminService.addServiceTerm({
      body: {
        fromDate,
        toDate
      }
    });
  };

  @action
  getServiceTerm = (serviceTermId: string) => {
    return toJS(
      this.serviceTerms.find(term => term.serviceTermId === serviceTermId)
    );
  };

  @action
  setServiceTerms = (terms: Array<ServiceTerm>) => {
    this.serviceTerms = terms;
  };

  @computed
  get termsOptions() {
    return this.serviceTerms.map(term => ({
      label: `${term.fromDate} ~ ${term.toDate}`,
      value: term.serviceTermId as string
    }));
  }

  @computed
  get serviceTermId() {
    // default first term
    let id = this.serviceTerms[0]?.serviceTermId;
    for (let i = 0; i < this.serviceTerms.length; i++) {
      if (inRange(this.serviceTerms[i].fromDate, this.serviceTerms[i].toDate)) {
        id = this.serviceTerms[i].serviceTermId;
        break;
      }
    }
    return id || '';
  }
}

export default new ServiceTerms();
