import User from './User';
import { LayoutHeader } from '@sagano/share/components';
import { isProd } from '@sagano/admin/utils/env';

const Header: React.FC = () => {
  const refresh = (e: React.MouseEvent<HTMLAnchorElement>) => {
    e.preventDefault();
    sessionStorage.clear();
    window.location.replace(e.currentTarget.href);
  };

  return (
    <LayoutHeader webTitle="Sagano" refreshHandle={refresh} isProd={isProd}>
      <User />
    </LayoutHeader>
  );
};

export default Header;
