export const EMAIL_REGEX =
  /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/;

export const PASSWORD_REGEX = /^(?=.*[A-Za-z])(?=.*\d).{8,}$/;
/* eslint-disable @typescript-eslint/no-explicit-any */
// eslint-disable-next-line
export const isObject = (obj: any): boolean => {
  return obj !== null && typeof obj === 'object';
};
export const toTypeString = (value: unknown): string =>
  objectToString.call(value);
export const objectToString = Object.prototype.toString;
export const isMap = (val: unknown): val is Map<any, any> =>
  toTypeString(val) === '[object Map]';
export const isSet = (val: unknown): val is Set<any> =>
  toTypeString(val) === '[object Set]';
export const isDate = (val: unknown): val is Date =>
  toTypeString(val) === '[object Date]';
export const isRegExp = (val: unknown): val is RegExp =>
  toTypeString(val) === '[object RegExp]';
export const isFunction = (val: unknown): val is (...args: any[]) => any =>
  typeof val === 'function';
export const isString = (val: unknown): val is string =>
  typeof val === 'string';
export const isSymbol = (val: unknown): val is symbol =>
  typeof val === 'symbol';
export const isPlainObject = (val: unknown): val is object =>
  toTypeString(val) === '[object Object]';
export function deepClone<T>(val: T): T {
  if (!isObject(val)) {
    return val;
  }
  if (Array.isArray(val)) {
    return val.map(deepClone) as typeof val;
  }
  if (isDate(val)) {
    return new Date(val) as typeof val;
  }
  if (isMap(val)) {
    return new Map(val) as typeof val;
  }
  if (isSet(val)) {
    return new Set(val) as typeof val;
  }
  if (isRegExp(val)) {
    return new RegExp(val.source, val.flags) as typeof val;
  }
  return Object.keys(val as object).reduce((acc, key) => {
    acc[key as keyof T] = deepClone(val[key as keyof T]);
    return acc;
  }, {} as T);
}

export const parseDate = (date?: string) => {
  if (date) {
    const [year, month, day] = date.split('-');
    return new Date(Number(year), Number(month) - 1, Number(day));
  } else {
    return new Date();
  }
};

export const inRange = (fromDate?: string, toDate?: string) => {
  const from = parseDate(fromDate);
  const to = parseDate(toDate);
  const today = new Date();
  return today >= from && today <= to;
};

export const validDateRange = (fromDate?: string, toDate?: string) => {
  const from = parseDate(fromDate);
  const to = parseDate(toDate);
  return from <= to;
};

export const dateFormat = 'yyyy-MM-dd';
export const timeFormat = 'HH:mm:ss';
export const timestampFormat = `${dateFormat} ${timeFormat}`;

export const formatE6Price = (price?: string) => {
  return Number(Number(price) / 1_000_000);
};

export const parseE6Price = (price?: number | string) => {
  return (Number(price) * 1_000_000).toString();
};
