export interface SpecificDateRadioProps {
  dayEndOfMonth?: boolean;
  day?: number;
  disabled?: boolean;
  onChange?: (dayEndOfMonth: boolean, day: number) => void;
}

export const PaymentType = {
  monthEnd: 'MONTH_END',
  specificDate: 'SPECIFIC_DATE'
} as const;

export type PaymentType = (typeof PaymentType)[keyof typeof PaymentType];
