import { action, computed, makeObservable, observable } from 'mobx';
import { sleep } from '@linktivity/link-utils';
import { UserDetail, User, Claims, UserCredential } from '../services/types';

const storage = localStorage;
const ACCESS_TOKEN = 'accessToken';

const MAX_REFRESH_COUNT = 10;

const TIMEOUT = 300;

export default class {
  USER_DETAIL = 'userDetail';
  @observable user: User | null = null;
  @observable claims: Claims | null = null;
  @observable accessToken = storage.getItem(ACCESS_TOKEN) || '';
  @observable userDetail: UserDetail = JSON.parse(
    storage.getItem(this.USER_DETAIL) || '{}'
  );
  constructor() {
    makeObservable(this);
  }

  @action
  setUser = (user: User) => {
    this.user = user;
    this.getClaims(user);
  };
  @action
  setUserDetail = (userDetail: UserDetail) => {
    this.userDetail = userDetail;
  };

  @action
  getClaims = async (user: User) => {
    const tokenResult = await user.getIdTokenResult();
    if (tokenResult) {
      this.setClaims(tokenResult.claims as unknown as Claims);
    }
  };
  @action
  setClaims = (claims: Claims) => {
    this.claims = claims;
  };

  @action
  clearUser = () => {
    this.user = null;
    this.setUserDetail({});
    try {
      storage.removeItem(ACCESS_TOKEN);
      storage.removeItem(this.USER_DETAIL);
    } finally {
      this.accessToken = '';
    }
  };
  @action
  clearAllStorage = () => {
    localStorage.clear();
    sessionStorage.clear();
  };
  @action
  updateToken = (credential: UserCredential) => {
    credential.user.getIdToken().then(token => {
      this.setToken(token);
    });
  };
  @action
  setToken = (token: string) => {
    try {
      storage.setItem(ACCESS_TOKEN, token);
    } finally {
      this.accessToken = token;
    }
  };

  @action
  getToken = async () => {
    if (this.user) {
      const token = await this.user.getIdToken();
      this.setToken(token);
      return token;
    } else {
      return '';
    }
  };

  @action
  getRefreshedToken = async () => {
    let count = 0;
    const requestToken: () => Promise<string> = async () => {
      const token = await this.getToken();
      if (token) {
        return token;
      } else {
        if (count < MAX_REFRESH_COUNT) {
          count++;
          await sleep(TIMEOUT);
          return await requestToken();
        } else {
          return '';
        }
      }
    };
    return await requestToken();
  };

  @computed
  get loggedIn(): boolean {
    return !!this.user || !!this.accessToken;
  }

  @computed
  get email(): string {
    return this.user?.email || '';
  }

  @computed
  get userType(): string {
    return this.claims?.user_type || '';
  }

  @computed
  get userGroup(): string {
    return this.claims?.user_group || '';
  }
  @computed
  get userId(): string {
    return this.claims?.user_id || '';
  }

  @computed
  get isAdmin(): boolean {
    return this.userType === 'admin';
  }
}
