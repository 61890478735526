import { Updater } from 'use-immer';
import { FormValues } from '@linktivity/link-ui';
import { IOption } from '@sagano/share/services/types';
import { currentMonthStart, currentMonthEnd } from '../../utils';
import { AgentType, IRole } from '../../services/const';

export interface BookingListSearchFiltersProps {
  filters?: FormValues;
  onSubmit: (filters: FormValues) => void;
  onFiltersChange?: Updater<FormValues>;
  asyncOptions?: Record<string, IOption[]>;
  role: IRole;
  forSales?: boolean;
}

export const commonFilters: FormValues = {
  submittedAtFrom: '',
  submittedAtTo: '',
  get serviceDateFrom() {
    return currentMonthStart();
  },
  get serviceDateTo() {
    return currentMonthEnd();
  },
  bookingId: '',
  agentSubGroupIds: '',
  groupTypes: '',
  groupName: '',
  status: [],
  notificationMsg: ''
};

export const adminBookingFilters: FormValues = {
  ...commonFilters,
  agentId: '',
  subStatus: [],
  countryCode: '',
  paymentType: '',
  contract: AgentType.Free
};
export const adminSalesFilters: FormValues = {
  ...adminBookingFilters,
  paymentStatus: []
};
export const agentSalesFilters: FormValues = {
  ...commonFilters,
  paymentStatus: []
};
