import { useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { Outlet, useParams } from 'react-router-dom';
import { useStore } from '@sagano/admin/stores';
import { Loading } from '@linktivity/link-ui';
const DetailLayout = observer(() => {
  const { bookingDetail, units } = useStore();
  const { bookingId } = useParams();
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    units.fetchUnits().then(() => {
      bookingDetail.setUnitMap(units.unitMap);
      bookingDetail
        .getDetail(bookingId as string)
        .finally(() => setLoading(false));
    });
  }, [bookingDetail, bookingId, units]);
  return loading ? (
    <div className="loading">
      <Loading />
    </div>
  ) : (
    <Outlet />
  );
});

export default DetailLayout;
