import React from 'react';
import arrangementTypes, { ArrangementTypes } from './modules/arrangementTypes';
import auth, { Auth } from './modules/auth';
import serviceTerms, { ServiceTerms } from './modules/serviceTerms';
import stations, { Stations } from './modules/stations';
import trains, { Trains } from './modules/trains';
import units, { Units } from './modules/units';
import reservation, { Reservation } from './modules/reservation';
import bookingDetail, { BookingDetail } from './modules/bookingDetail';
export type RootStore = {
  arrangementTypes: ArrangementTypes;
  auth: Auth;
  serviceTerms: ServiceTerms;
  stations: Stations;
  trains: Trains;
  units: Units;
  reservation: Reservation;
  bookingDetail: BookingDetail;
};

export const store: RootStore = {
  arrangementTypes,
  auth,
  serviceTerms,
  stations,
  trains,
  units,
  reservation,
  bookingDetail
};

const StoreContext = React.createContext<RootStore>(store);

export const StoreProvider = StoreContext.Provider;

export const useStore = () => React.useContext(StoreContext);

export {
  arrangementTypes,
  auth,
  serviceTerms,
  stations,
  trains,
  units,
  reservation,
  bookingDetail
};
