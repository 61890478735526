import { useState, useMemo, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { Dropdown, message } from '@linktivity/link-ui';
import { ReactComponent as UserIcon } from '../../assets/icons/user.svg';
import { BaseAuth } from '@sagano/share/stores';
import styles from './layoutUser.module.css';

interface LayoutUserProps {
  auth: BaseAuth;
  onLogout: () => void;
}

const LayoutUser: React.FC<LayoutUserProps> = ({ auth, onLogout }) => {
  const { t } = useTranslation();
  const [openMenu, setOpenMenu] = useState(false);

  const userButton = useMemo(
    () => (
      <button
        className={styles.button}
        type="button"
        onClick={() => setOpenMenu(visible => !visible)}
      >
        <UserIcon className={styles.icon} />
        <span className={styles.text}>{t('share.common.account')}</span>
      </button>
    ),
    [t]
  );

  const showMessage = useCallback((msg: string) => {
    message.info(msg, 10);
  }, []);

  return (
    <Dropdown
      target={userButton}
      visible={openMenu}
      sameWidth={false}
      placement="bottom-end"
      onClose={() => setOpenMenu(false)}
    >
      <Dropdown.Menu className={styles.dropdownMenu}>
        <Dropdown.Item>
          <button
            className={styles.dropdownButton}
            onClick={() => showMessage(auth.email)}
          >
            <p className={styles.dropdownText}>{auth.email}</p>
          </button>
        </Dropdown.Item>
        <Dropdown.Item>
          <button className={styles.dropdownButton} onClick={onLogout}>
            {t('share.common.signOut')}
          </button>
        </Dropdown.Item>
      </Dropdown.Menu>
    </Dropdown>
  );
};

export default LayoutUser;
