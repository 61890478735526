import { action } from 'mobx';
import { BookingDetail as BaseBookingDetail } from '@sagano/share/stores';
import { adminService } from '@sagano/admin/services';

export class BookingDetail extends BaseBookingDetail {
  @action async getDetail(bookingId: string) {
    return adminService.getBookingDetails({ bookingId }).then(res => {
      this.setBookingDetail(res);
    });
  }
  @action async listNote(bookingId: string) {
    return adminService.listNote({ bookingId }).then(res => {
      this.setNotes(res.notes || []);
    });
  }
}

export default new BookingDetail();
