import React, { useContext } from 'react';
import { BaseCarContextProps } from './BaseCar.types';

const defaultContext = {
  car: {},
  selectedArrangementType: {}
};

export const BaseCarContext =
  React.createContext<BaseCarContextProps>(defaultContext);

export const useBaseCarContext = () => useContext(BaseCarContext);
