import React, { useEffect } from 'react';
import { Outlet } from 'react-router-dom';
import { useStore } from '@sagano/admin/stores';
import Header from '../Header';
import Sidebar from '../Sidebar';
import styles from './base.module.css';

const BaseLayout: React.FC = () => {
  const { stations, trains, units, arrangementTypes } = useStore();

  useEffect(() => {
    stations.fetchStations();
  }, [stations]);

  useEffect(() => {
    trains.fetchTrains();
  }, [trains]);

  useEffect(() => {
    units.fetchUnits();
  }, [units]);

  useEffect(() => {
    arrangementTypes.fetchArrangementTypes();
  }, [arrangementTypes]);

  return (
    <div className={styles.base}>
      <Header />
      <div className={styles.container}>
        <Sidebar />
        <main className={styles.main}>
          <div className={styles.inner}>
            <Outlet />
          </div>
        </main>
      </div>
    </div>
  );
};

export default BaseLayout;
